import datepicker from "%modules%/profile/datepicker.min";
import maskInput from "vanilla-text-mask/dist/vanillaTextMask";

document.addEventListener('DOMContentLoaded', () => {
  const birthday = '#profile-birthday';
  const birthdayInput = document.querySelector(birthday);

  if (birthdayInput) {
    const curDate = new Date();
    const maxDateYear = curDate.getFullYear() - 6;
    const maxDateMonth = curDate.getMonth();
    const maxDateDay = curDate.getDate();
    const maxDate = new Date(maxDateYear, maxDateMonth, maxDateDay)
    datepicker(birthday, {
      startDay: 1,
      customDays: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
      customMonths: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
      overlayPlaceholder: 'введите год',
      overlayButton: 'выбрать',
      customOverlayMonths: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
      maxDate: maxDate,
      showAllDates: true,
      formatter: (input, date, instance) => {
        input.value = date.toLocaleDateString('ru')
      },
    })
  }

  const phoneMask = ['+', '7', ' ', '(', /[9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
  const phoneInput1 = document.getElementById('profile-phone');
  const phoneInput2 = document.getElementById('subscribe-phone');

  const phoneInputs = [phoneInput1, phoneInput2];

  phoneInputs.forEach(phoneInput => {
    if (phoneInput) {
      maskInput({
        inputElement: phoneInput,
        mask: phoneMask
      })
    }
  })
})