import Swiper from 'swiper';

document.addEventListener('DOMContentLoaded', () => {
  const slider = document.getElementById('ds-store-one-slider');

  new Swiper(slider, {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 20,
    allowTouchMove: true,
    breakpoints: {
      768: {
        slidesPerView: 3,
        allowTouchMove: false
      }
    },
    pagination:{
      el: '#ds-store-one-pagination',
      type: 'bullets',
      clickable: true,
      bulletActiveClass: 'm-active'
    }
  })
})