import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

class GsapAnimation {
    constructor (selector) {
        if (!selector) {
            return
        }

        this.container = document.querySelector(selector)
        this.transformedEls = this.container.querySelectorAll('[data-scroll-translate]')
        this.counterEls = this.container.querySelectorAll('[data-scroll-counter]')
        this.parallaxEls = this.container.querySelectorAll('[data-scroll-parallax]')
        this.scaledEls = this.container.querySelectorAll('[data-scroll-scale]')
        this.revealedEls = this.container.querySelectorAll('[data-scroll-reveal]')
        this.initScrollTrigger()
    }

    initScrollTrigger () {
        this.transformedEls.forEach((el) => {
            const direction = el.dataset.scrollTranslate
            let yOffset
            let xOffset
            switch (direction) {
                case 'bottom':
                    yOffset = '-10%'
                    break
                case 'top':
                    yOffset = '10%'
                    break
                case 'left':
                    xOffset = '-10%'
                    break
                case 'right':
                    xOffset = '10%'
                    break
                default:
                    yOffset = '-10%'
                    xOffset = ''
            }
            gsap.set(el, {
                autoAlpha: 0,
                y: yOffset,
                x: xOffset
            })
            gsap.to(el, {
                scrollTrigger: {
                    trigger: el,
                    start: '10% 80%',
                    toggleActions: 'play none none reverse'
                },
                autoAlpha: 1,
                y: 0,
                x: 0,
                duration: 1,
                ease: 'power3.out'
            })
        })

        this.scaledEls.forEach((el) => {
            gsap.to(el, {
                scale: 0.95
            }, {
                scrollTrigger: {
                    trigger: el,
                    start: 'center 80%',
                    toggleActions: 'play none none reverse'
                },
                scale: 1,
                duration: 0.6,
                ease: 'power3.out'
            })
        })

        this.revealedEls.forEach((el) => {
            gsap.to(el, {
                autoAlpha: 0
            }, {
                scrollTrigger: {
                    trigger: el,
                    start: 'center 90%',
                    toggleActions: 'play none none reverse'
                },
                autoAlpha: 1,
                duration: 0.3,
                ease: 'power3.out'
            })
        })

        this.parallaxEls.forEach((el) => {
            gsap.to(el, {
                scrollTrigger: {
                    trigger: el,
                    scrub: true
                },
                yPercent: -75,
                ease: 'none'
            })
        })

        this.counterEls.forEach((el) => {
            let counter = {
                var: 0
            }
            gsap.to(counter, {
                scrollTrigger: {
                    trigger: el
                },
                var: el.dataset.scrollCounter,
                duration: 3,
                snap: {
                    innerText: 1
                },
                onUpdate: function () {
                    el.innerHTML = Math.ceil(counter.var).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
                },
            })
        })
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const pageAboutNode = document.querySelector('.page--about')
    const aboutWelcomeSwiperNode = document.querySelector('.about-welcome-swiper')
    const aboutHistoryThumbsSwiperNode = document.querySelector('.about-history-thumbs-swiper')
    const aboutHistorySwiperNode = document.querySelector('.about-history-swiper')

    if (pageAboutNode) {
        new GsapAnimation('.page--about')
    }

    if (aboutWelcomeSwiperNode) {
        const welcomeSwiper = new Swiper(aboutWelcomeSwiperNode, {
            autoplay: {
                delay: 5000
            },
            loop: true,
            slidesPerView: 1,
            spaceBetween: 64,
            navigation: {
                nextEl: ".js-about-welcome-swiper-next",
                prevEl: ".js-about-welcome-swiper-prev"
            },
            pagination: {
                el: ".js-about-welcome-swiper-pagination",
                clickable: true
            }
        })
    }

    if(aboutHistoryThumbsSwiperNode && aboutHistorySwiperNode){
        const historyThumbsSwiper = new Swiper(aboutHistoryThumbsSwiperNode, {
            slidesPerView: 'auto',
            spaceBetween: 10,
            allowTouchMove: false,
            watchSlidesProgress: true,
            scrollbar: {
                el: ".js-about-history-thumb-swiper-scrollbar",
                hide: false,
                draggable: true
            }
        })

        const historySwiper = new Swiper(aboutHistorySwiperNode, {
            effect: 'fade',
            slidesPerView: 1,
            spaceBetween: 100,
            navigation: {
                nextEl: ".js-about-history-swiper-next",
                prevEl: ".js-about-history-swiper-prev"
            },
            thumbs: {
                swiper: historyThumbsSwiper,
                autoScrollOffset: 1
            }
        })
    }
})
