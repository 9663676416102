import $ from 'jquery'

    function Stabs(obj) {
        this.tabItem = obj.tabItem;
        this.tabLink = obj.tabLink;
        this.tabSub = obj.tabSub;
        this.openAll = obj.openAll || false;
        this.liActiveEl = obj.liActiveEl;
        this.toggleClass = (el, i)=> {

            $(el).removeClass("active").eq(i).addClass("active");

        }
        const stabs = this;

        // $(document).ready(function() {
        //
        //     $(stabs.tabLink).eq(0).addClass("active");
        //
        // });
        $(document).on('click', stabs.tabLink, function(event) {
            //event.preventDefault();


                let ind = $(this).parent().index();

                const $th  = $(this);
                if (stabs.liActiveEl) {
                    ind = $(this).index();
                }


                if (stabs.openAll) {

                    if ($th.hasClass('active')){
                        $th.removeClass('active');

                        $(stabs.tabSub).eq(ind).stop().slideUp('fast');


                    }else {
                        $th.addClass('active');

                        $(stabs.tabSub).eq(ind).stop().slideDown('fast');
                    }


                }else {


                    if ($th.hasClass('active')){
                        $th.removeClass('active');

                        $(stabs.tabSub).stop().slideUp('fast');
                    }else {
                        $(stabs.tabLink).removeClass('active');
                        $th.addClass('active');

                        $(stabs.tabSub).stop().slideUp('fast').eq(ind).stop().slideDown('fast');
                    }


                }
        })
    }

    var catTabs = new Stabs({
        tabLink: '[data-acc-list-btn]',
        liActiveEl: false,
        openAll: false,
        tabSub: '[data-acc-list-sub]',
        tabItem: '[data-acc-list-item]',
    });


$(document).ready(function() {



    if(document.documentElement.clientWidth <= 991) {

        var mobTabs = new Stabs({
            tabLink: '[data-acc-list-mob-btn]',
            liActiveEl: false,
            openAll: false,
            tabSub: '[data-acc-list-mob-sub]',
            tabItem: '[data-acc-list-mob-item]',
        });


        var mobFilterTabs = new Stabs({
            tabLink: '[data-acc-f-mob-btn]',
            liActiveEl: false,
            openAll: false,
            tabSub: '[data-acc-f-mob-sub]',
            tabItem: '[data-acc-f-mob-item]',
        });



    }


});

/*
     $(document).on('click', '[data-acc-list-btn]', function(event) {
             event.preventDefault();

             const $th = $(this),
                 $parent = $th.parents('[data-acc-list]'),
                    $allBtn = $parent.find('[data-acc-list-btn]'),
                    $allLSub = $parent.find('[data-acc-list-sub]'),

         });
*/


