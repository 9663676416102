export default () => {
	let mapShops = null;
	let objectManagerShops = null;
	let mapCenterShops = [55.775526, 37.579175];
	let zoomShops = 10;
	let pointIconShops = '/img/sprites/intermediate-svg/poin.svg'
	let pointsShops = (window.checkout.shopsPoints ? window.checkout.shopsPoints : []);
	let currentShopPoint = null;

	const getPointsInYandexMapFormatShops = (points) => {
		const featureCollection = {
			type: 'FeatureCollection',
			features: []
		};

		if(points)
		{
			points.forEach(point => {
				if(!point.coords)
					point.coords = getShopsPointCoords(point);
           
				featureCollection.features.push(
				{
					type: 'Feature',
					id: point.storeXmlId,
					geometry: {
						type: 'Point',
						coordinates: point.coords
					},
					options: {
						iconLayout: 'default#image',
						iconImageHref: pointIconShops,
						iconImageSize: [56, 78],
						iconImageOffset: [-28, -70]
					}
				});
			});
		}

		return JSON.stringify(featureCollection);
	}

	const initShops = () => {
		mapShops = new ymaps.Map('map-popup-shop',
		{
			center: mapCenterShops,
			zoom: zoomShops,
			controls: []
		},
		{
			maxZoom: 18,
			minZoom: 8
		});

		objectManagerShops = new ymaps.ObjectManager(
		{
			clusterize: true,
			preset: 'islands#blackClusterIcons',
			groupByCoordinates: false,
			clusterHasBalloon: false,
			clusterHideIconOnBalloonOpen: false,
			geoObjectHideIconOnBalloonOpen: false,
			geoObjectOpenBalloonOnClick: window.innerWidth >= 768
		});

		objectManagerShops.add(getPointsInYandexMapFormatShops(pointsShops));

		objectManagerShops.objects.events.add('click', (e) => {
			e.stopPropagation();
			clickOnPointShops(e.get('objectId'));
		});

		mapShops.geoObjects.add(objectManagerShops);

		if(pointsShops.length === 1)
			mapShops.setCenter(getShopsPointCoords(pointsShops[0]), 10)
		else if(pointsShops.length !== 0)
			mapShops.setBounds(objectManagerShops.getBounds(), {checkZoomRange: true});
	};

	const shopsItems = document.querySelector('.ds-popup-shop-items');
	const shopsOne = document.querySelector('.ds-popup-shop-one');
	const shopsMap = document.querySelector('.ds-popup-shop-map');
	const shopsBack = document.querySelector('.ds-popup-shop-back');
	const shopsOneItems = {
		title: shopsOne.querySelector('#popup-shop-one-title'),
		type: shopsOne.querySelector('#popup-shop-one-type'),
		address: shopsOne.querySelector('#popup-shop-one-address'),
		selectButton: shopsOne.querySelector('.ds-popup-shop-select'),
		work: shopsOne.querySelector('#popup-shop-one-work'),
		phone: shopsOne.querySelector('#popup-shop-one-phone'),
		brands: shopsOne.querySelector('#popup-shop-one-brands'),
		metro: shopsOne.querySelector('#popup-shop-one-metro'),
	}
	const popupShop = document.querySelector('.ds-popup-shop');
	const popupShopCity = popupShop.querySelector('#popup-shop-address')
	
	shopsItems.addEventListener('click', ({target}) => {
		const point = target.closest('.ds-popup-shop-point');
		
		if(point)
			openShopDetails(point.getAttribute('data-id'))
	});

	shopsBack.addEventListener('click', () => {
		closeShopDetails();
	});

	function clickOnPointShops(pointId)
	{
		openShopDetails(pointId);
	}

	function openShopDetails(pointId)
	{
		currentShopPoint = window.checkout.shopsPoints.find(({storeXmlId}) => storeXmlId == pointId);
		shopsItems.setAttribute('data-hidden', '');
		shopsOne.removeAttribute('data-hidden');
		shopsMap.setAttribute('data-active', '');
		mapShops.setCenter(getShopsPointCoords(currentShopPoint), 17);
		updateShopPointsData(currentShopPoint);
	}

	function closeShopDetails()
	{
		shopsMap.removeAttribute('data-active');
		shopsOne.setAttribute('data-hidden', '');
		shopsItems.removeAttribute('data-hidden');
		
		if(currentShopPoint)
			mapShops.setCenter(getShopsPointCoords(currentShopPoint), 10);
	}

	function updateShopPointsData(point)
	{
		shopPopupShowPreloader();

		if(shopsOneItems.title)
			shopsOneItems.title.innerHTML = point.name;

		if(shopsOneItems.type)
			shopsOneItems.type.innerHTML = (point.manufacturer ? point.manufacturer : '');

		if(shopsOneItems.address)
			shopsOneItems.address.innerHTML = (point.address ? point.address : '');

		if(shopsOneItems.selectButton)
			shopsOneItems.selectButton.setAttribute('data-point-id', point.id);

		setPointData(shopsOneItems.work, point.workTime);
		setPointData(shopsOneItems.phone, point.phone);
		setPointData(shopsOneItems.brands, point.brands);

		if(shopsOneItems.metro)
		{
			if(!point.metro)
			{
				shopsOneItems.metro.previousElementSibling.classList.add('m-hidden')
				shopsOneItems.metro.innerHTML = ''
			}
			else
			{
				shopsOneItems.metro.previousElementSibling.classList.remove('m-hidden')
			}

			if(typeof point.metro === 'object')
			{
				const isNotEmptyValue = Object.values(point.metro).find(el => el && el.length > 0);
				
				if(isNotEmptyValue)
				{
					shopsOneItems.metro.innerHTML = `<p class="store-one__text m-with-icons"></p>`;
					const metroNode = shopsOneItems.metro.firstElementChild;
					
					for(const [key, value] of Object.entries(point.metro))
					{
						if(value)
						{
							const newMetro = document.createElement('span');
							newMetro.style.cssText = `--circle-color: ${key}`;
							newMetro.innerHTML = value;
							metroNode.appendChild(newMetro);
						}
					}
				}
				else
				{
					shopsOneItems.metro.previousElementSibling.classList.add('m-hidden');
					shopsOneItems.metro.innerHTML = '';
				}
			}
			else
			{
				shopsOneItems.metro.innerHTML = point.metro ? point.metro : '';
			}
		}

		shopPopupHidePreloader();

		function setPointData(item, param)
		{
			if(item)
			{
				if(!param || !param.length)
					item.previousElementSibling.classList.add('m-hidden');
				else
					item.previousElementSibling.classList.remove('m-hidden');
				
				item.innerHTML = (param ? param : '');
			}
		}
	}

	function shopPopupShowPreloader()
	{
		popupShop.classList.add('skeleton');
		popupShopCity.setAttribute('disabled', '');
	}

	function shopPopupHidePreloader()
	{
		popupShop.classList.remove('skeleton');
		popupShopCity.removeAttribute('disabled');
	}

	function getShopsPointCoords(point)
	{
		return point.coords ? point.coords : [parseFloat(point.lat), parseFloat(point.lng)];
	}

	function updateMapShopsPoints(newPoints)
	{
		objectManagerShops.removeAll();
		objectManagerShops.add(getPointsInYandexMapFormatShops(newPoints));
		
		if(newPoints.length === 1)
			mapShops.setCenter(getShopsPointCoords(newPoints[0]), 10)
		else if(newPoints.length !== 0)
			mapShops.setBounds(objectManagerShops.getBounds(), {checkZoomRange: true});
	}

	window.popupShop.updateMapPoints = (newPoints) => {
		if(objectManagerShops)
			updateMapShopsPoints(newPoints);
		else
			ymaps.ready(() => updateMapShopsPoints(newPoints))
	}

	window.popupShop.updateMapCenter = () => {
		mapShops.setBounds(objectManagerShops.getBounds(), {checkZoomRange: true});
	}

	window.popupShop.showPreloader = () => shopPopupShowPreloader();
	window.popupShop.hidePreloader = () => shopPopupHidePreloader();
	window.popupShop.hideDetailInfo = () => closeShopDetails();
	ymaps.ready(initShops);
}
