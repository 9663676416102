import Swiper from 'swiper';

export default () => {
    window.cardSlider.sliderInit = (itemsSliders) => {
        itemsSliders ??= document.getElementsByClassName('card__js-slider');
        Array.from(itemsSliders).forEach(function (slider) {
            const allSlides = slider.querySelectorAll('.swiper-slide');
            if (allSlides.length > 1) {
                let wrapperSlider = slider.querySelector('.card__slider');
                wrapperSlider.classList.add('swiper-wrapper');
                allSlides.forEach((el) => {
                    el.style.display = "flex";
                });
                new Swiper(slider, {
                    slidesPerView: 1,
                    pagination: {
                        el: ".swiper-pagination",
                        bulletActiveClass: "card__hvr-dot--active",
                        bulletClass: "card__hvr-dot",
                    },
                });
            }
        })
    }
}