import bodyScrollLock from '../m-scroll-lock/m-scroll-lock'
import $ from "jquery";

let headerBurger = document.getElementsByClassName('js-menu');

Array.from(headerBurger).forEach(button => button.addEventListener('click', function (e) {

    const th = e.target.closest('.js-menu'),

        $nav = $('.js-nav'),
        $link = $('[data-open-mob-menu]');

    if (document.body.classList.contains('menu-opened')) {
        bodyScrollLock(true);
        document.body.classList.remove('menu-opened');
        $nav.fadeOut();
        th.classList.remove('active');
        $link.removeClass('active');
        $('[data-menu-nav]').hide();
    } else {
        bodyScrollLock(false);
        document.body.classList.add('menu-opened');

        if (!$nav.hasClass('js-header')){
            $nav.addClass('js-header')
        }

        $nav.fadeIn();
        th.classList.add('active');
    }
    e.preventDefault();
}));

document.addEventListener('DOMContentLoaded', () => {
    const menuButton = document.querySelector('.js-menu');
    const activeSection = document.querySelector('.active-section');
    if (activeSection) {
        menuButton.addEventListener('click', () => {
            if (document.body.classList.contains('menu-opened')) {
                activeSection.scrollIntoView({ behavior: 'smooth', block: 'center'});
            }
        });
    }
})

