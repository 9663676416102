import maskInput from "vanilla-text-mask";

document.addEventListener('DOMContentLoaded', () => {
  window.fields = {};
  const allEmailInputs = document.querySelectorAll('[data-type="email"]')

  /**
   * валидация email
   * **/
  allEmailInputs.forEach(input => {
    /**
     * скрываем ошибки при фокусе на поле
     * **/
    input.addEventListener('focus', () => {
      if (input.classList.contains('error')) {
        removeError(input);
      }
    })

    /**
     * показываем ошибку на незаполненном поле при блюре
     * **/
    input.addEventListener('blur', () => {
      if (isEmailError(input)) {
        setError(input, input.value.length ? 'Введите корректный email' : 'Обязательное поле');
      }
    })
  })

  /**
   * маска на номер телефона
   * **/
  const phoneMask = ['+', '7', ' ', '(', /[9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
  const allPhoneInputs = document.querySelectorAll('[data-type="phone"]');

  if (allPhoneInputs.length) {
    allPhoneInputs.forEach(phoneInput => {
      maskInput({
        inputElement: phoneInput,
        mask: phoneMask
      });
    })
  }

  /**
   * установка ошибки для инпута
   * **/
  function setError(inputNode, text = 'Обязательное поле') {
    const errorMessage = document.createElement('p');
    errorMessage.classList.add('input__error');
    errorMessage.innerHTML = text;
    inputNode.classList.add('error');
    inputNode.after(errorMessage);
  }

  /**
   * удаление ошибки для инпута
   * **/
  function removeError(inputNode) {
    inputNode.classList.remove('error');
    const errorNode = inputNode.nextElementSibling;
    inputNode.parentElement.removeChild(errorNode);
  }

  /**
   * валидация значения email
   * **/
  function emailValidation(value) {
    const valueFirst = value.split('@');
    const regexp = /^[A-Za-z0-9_.@-]+$/i;
    //проверка на латиницу
    if (!regexp.test(value)) {
      return false;
    }
    //проверка на длину после @
    if (valueFirst.length > 1) {
      const valueSecond = valueFirst[1].split('.');
      return valueSecond.length > 1 && valueSecond[1].length >= 2;
    }
    return false;
  }

  /**
   * валидация email
   * **/
  function isEmailError(inputNode) {
    //проверяем длину поля
    if (inputNode.value.length) {
      //проверка значения в поле
      return !emailValidation(inputNode.value);
    } else {
      //ошибка пустого поля
      return true;
    }
  }

  window.fields.emailValidation = (inputNode) => isEmailError(inputNode);
})