document.addEventListener('DOMContentLoaded', () => {
	const oCookiePopup = document.querySelector('.js-cookie-popup'),
		oCookiePopupBtn = oCookiePopup.querySelector('.js-cookie-popup-btn'),
		sStateCookiePopup = BX.getCookie('cookiePopup');

	if(!sStateCookiePopup)
	{
		oCookiePopup.classList.add('is-open');

		oCookiePopupBtn.addEventListener('click' , () => {
			$.post('/local/ajax/cookie.php', {'sessid': BX.bitrix_sessid()});
			BX.setCookie('cookiePopup', true, {path: '/', expires: (60 * 60 * 24 * 365)});
			oCookiePopup.classList.remove('is-open');
		});
	}
});