import $ from 'jquery'

$.fn.hasAttr = function (name) {
    return this.attr(name) !== undefined;
};
const nav = $('[data-menu-nav]'),
    body = document.querySelector('body'),
    items = $('[data-menu-category]');


items.on('mouseenter', function (event) {


if(document.documentElement.clientWidth > 991) {

    event.stopPropagation()

    const th = $(this),
        wrap = th.find('[data-category-container]'),
        hiddenList = th.find('[data-category-list]'),
        hiddenListHeight = hiddenList.outerHeight();

    if (!nav.hasAttr('data-open')) {
        nav.attr('data-open', "");
        body.classList.add('menu-open');
    }

    if (hiddenList.length > 0) {
        wrap.css({
            maxHeight: hiddenListHeight,

            zIndex: '2'

        });
        th.attr('data-top', "");
        $('#search-popup').attr('data-hide', '');
    }
}
});

items.on('mouseleave', function (event) {

    // event.stopPropagation()
    if(document.documentElement.clientWidth > 991) {
        const th = $(this),
            wrap = th.find('[data-category-container]'),
            target = $(event.target);
        // hiddenList =  th.find('[data-category-list]'),
        // hiddenList2 =  target.find('[data-category-list]');

        if (nav.hasAttr('data-open')) {
            nav.removeAttr('data-open');
            body.classList.remove('menu-open');
        }
        if (th.hasAttr('data-top')) {
            th.removeAttr('data-top');
        }

        wrap.css({
            maxHeight: 0,
           // zIndex: '1'
        })

    }
//console.log(target)
    //console.log(target.hasAttr('data-menu-category'))

});
/*
$(document).on('click', function (event) {

    if ($(event.target).closest('[data-custom-select-wrap]').length === 0) {

        $('[data-custom-select]').each(function (index, el) {

            if ($(el).hasClass('selected')) {
                $(el).removeClass('selected');
                $(el).parents('[data-custom-select-wrap]').find('[data-custom-select-dropdown]').stop(true).fadeOut();
            }
        });

    }

});
*/
