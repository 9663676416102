// let tabsWarp = document.getElementsByClassName('tabs');
//
// Array.from(tabsWarp).forEach(function (currentTabsWarp) {
//
//     let Tabs = currentTabsWarp.getElementsByClassName('js-tab-links__link');
//
//     let Panes = currentTabsWarp.getElementsByClassName('tabs-content__item');
//
//     Array.from(Tabs).forEach(tab => tab.addEventListener('click', function(e){
//
//         let index = Array.from(Tabs).indexOf(this);
//
//         [].forEach.call(Tabs, function(el) {
//             el.classList.remove("is-active");
//         });
//
//         [].forEach.call(Panes, function(el) {
//             el.classList.remove("is-active");
//         });
//
//         this.classList.add('is-active');
//
//         Panes[index].classList.add('is-active');
//
//         e.preventDefault();
//     }));
// });
//
// $('[data-tab]').each(function(index, el) {
//
//     $(this).find('[data-tab-link]').
//
// });

document.addEventListener('DOMContentLoaded', () => {

    $('[data-tab]').each(function (index, el) {

        $(this).find('[data-tab-item]').eq(0).show('fast');

    });

});


$(document).on('click', '[data-tab-link]', function (event) {
    event.preventDefault();

    const $th = $(this),
        attr = $th.attr('data-tab-link'),
        $parent = $th.closest('[data-tab]'),
        $links = $parent.find('[data-tab-link]'),
        $item = $parent.find('[data-tab-item]');

    if (!$th.hasClass('active')) {

        $links.removeClass('active');
        $th.addClass('active');
        // $item.css({
        // 	display: 'none',
        //
        // });
        $item.fadeOut('fast');

        // $(`[data-tab-item='${attr}']`).css({
        //     display: 'block',
        //
        // });
        $(`[data-tab-item='${attr}']`).fadeIn('fast');

    }


});
