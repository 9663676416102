import tippy from 'tippy.js';
window.tippy = tippy;

document.addEventListener('DOMContentLoaded', () => {
	const templateCashbackBadge = document.querySelector('.js-cashback-badge-tooltip-content');

	if (templateCashbackBadge) {
		tippy('.cashback-badge__icon', {
			theme: 'whitebox',
			interactive: true,
			content: templateCashbackBadge.innerHTML,
			allowHTML: true,
			placement: 'top-end',
			appendTo: () => document.body
		});
	}
})