export default () => {
  window.popupAdmission = {};
  const form = document.querySelector('.ds-popup-admission-form');
  const emailInput = form.querySelector('#popup-admission-email');
  const checkbox = form.querySelector('#popup-admission-checkbox');
  const openButtons = document.querySelectorAll('.ds-popup-admission-open');
  const submitBtn = form.querySelector('.ds-popup-admission-submit');

  openButtons.forEach(btn => {
    btn.addEventListener('click', () => {
      checkFields();
    })
  })

  emailInput.addEventListener('change', ()=>{
    checkFields();
  })

  checkbox.addEventListener('change', ()=>{
    checkFields();
  })

  function checkFields() {
    const isEmail = emailInput.value.length > 0 && !window.fields.emailValidation(emailInput);
    const isCheckbox = checkbox.checked;
    if (isEmail && isCheckbox) {
      submitBtn.removeAttribute('disabled')
    }else{
      submitBtn.setAttribute('disabled', '')
    }
  }

  function showPreloader(){
    emailInput.setAttribute('disabled', '');
    submitBtn.classList.add('preloader')
  }

  function hidePreloader(){
    emailInput.removeAttribute('disabled');
    submitBtn.classList.remove('preloader')
  }

  window.popupAdmission.showPreloader = () => showPreloader();
  window.popupAdmission.hidePreloader = () => hidePreloader();
}