import Swiper from 'swiper';
window.Swiper = Swiper;

document.addEventListener('DOMContentLoaded', ()=>{
    let itemsSliders = document.getElementsByClassName('js-slider');

    Array.from(itemsSliders).forEach(function (slider) {
        let itemsSwiper = new Swiper(slider, {
            loop: true,
            speed: 600,
            spaceBetween: 20,
            slidesPerView: 2,
            navigation: {
                nextEl: slider.parentElement.getElementsByClassName('custom-slider__arrow--next')[0],
                prevEl: slider.parentElement.getElementsByClassName('custom-slider__arrow--prev')[0]
            },
            breakpoints: {
                768: {
                    spaceBetween: 42,
                    slidesPerView: 3,
                    pagination: false
                },
                1200: {
                    spaceBetween: 20,
                    slidesPerView: 4,
                    pagination: false
                },
                1600: {
                    spaceBetween: 15,
                    slidesPerView: 6,
                    pagination: false
                },
            }
        });
    })
});
