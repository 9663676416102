import $ from 'jquery';
import '../../js/import/pages/fields';
// import '../../blocks/modules/form-styler/form-styler'
//import bodyScrollLock from '../../blocks/modules/m-scroll-lock/m-scroll-lock';

function NavMenu() {
  this.link          = '[data-js-menu-link]';
  this.hiddenContent = '[data-js-menu-content]';
  this.item          = '[data-js-menu-item]';
  this.list          = '[data-menu-nav]';
  const menu         = this;

  $(document).ready(function () {
    $('[data-menu-category]').each(function (index, el) {
      const $self   = $(el),
            $hidden = $(el).find(menu.hiddenContent);

      if ($hidden.length > 0) {
        const $imageLink = $hidden.find('.image-link');
        if ($imageLink.length === 0) {
          $(el).addClass('item-not-empty');
        }
      }
    });
  });

  $(document).on('click', menu.link, function (event) {
    if (document.documentElement.clientWidth <= 1024) {
      const $target   = $(event.target),
            $th       = $(this),
            attr      = $(this).attr('data-js-menu-link'),
            // ind = $(this).parents('.menu-category__item').index(),
            $hiddenCt = $th.next(menu.hiddenContent);
      //console.log($th.hasClass('active'))

      /*
                        if (!$th.hasClass('active')) {

                            $th.addClass('active')

                            $hiddenCt.stop().slideDown('fast');


                        } else {
                            $th.removeClass('active');

                            $hiddenCt.stop().slideUp('fast');


                        }*/
      if (attr == 2) {
        if ($target.is('a')) {
          if (!$th.hasClass('active')) {
            $('[data-js-menu-link]').removeClass('active');
            $('[data-js-menu-content="2"]').stop().slideUp('fast');
            $('.menu-category__item:last-child')
              .find('[data-js-menu-content]')
              .stop()
              .slideUp('fast');

            $th.addClass('active');

            // $(menu.hiddenContent).stop().slideUp('fast').eq(ind).stop().slideDown('fast');
            $hiddenCt.stop().slideDown('fast');
          } else {
            $th.removeClass('active');
            $hiddenCt.stop().slideUp('fast');
          }
        }
      } else {
        if (!$th.parents('.menu-category__item').hasClass('item-not-empty')) {
          if (!$th.hasClass('active')) {
            $('[data-js-menu-link="2"]').removeClass('active');
            $('[data-js-menu-content="2"]').stop().slideUp('fast');

            $th.addClass('active');

            // $(menu.hiddenContent).stop().slideUp('fast').eq(ind).stop().slideDown('fast');
            $hiddenCt.stop().slideDown('fast');
          } else {
            $th.removeClass('active');
            $hiddenCt.stop().slideUp('fast');
          }
        }
      }
    }
  });
}

new NavMenu();

$(document).on('click', '[data-show-el]', function (event) {
  event.preventDefault();

  const attr = $(this).attr('data-show-el');

  $(`[data-hidden-el="${attr}"]`).slideDown();

  $(`[data-toggled-el="${attr}"]`).slideUp('fast');
});

$(document).on('click', '[data-hide-el]', function (event) {
  event.preventDefault();

  const attr = $(this).attr('data-hide-el');

  $(`[data-hidden-el="${attr}"]`).slideUp();

  $(`[data-toggled-el="${attr}"]`).slideDown('fast');
});

$(document).on('click', '[data-remove-btn]', function (event) {
  event.preventDefault();

  $(this)
    .parents('[data-remove-btn-parent]')
    .slideUp('fast', function () {
      $(this).remove();
    });
});

$(document).on('click', '[data-open-mob-menu]', function (event) {
  if (document.documentElement.clientWidth <= 991) {
    event.preventDefault();

    const th     = $(this),
          hidden = $('[data-menu-nav]'),
          attr   = th.attr('data-open-mob-menu'),
          links  = $('.mob-cats__link');

    if (th.hasClass('active')) {
      // th.removeClass('active');
      // hidden.slideUp();
    } else {
      $('[data-open-mob-menu]').removeClass('active');
      th.addClass('active');
      hidden.hide();
      $(`[data-menu-nav="${attr}"]`).slideDown();
    }
  }
});

$(window).scroll(function () {
  var $body = $('body');
  if (document.documentElement.clientWidth > 991) {
    if ($(this).scrollTop() > 10) {
      $body.addClass('scrolled');
    } else {
      $body.removeClass('scrolled');
    }
  }
});

document.addEventListener('DOMContentLoaded', () => {
  const mainSlider = document.querySelectorAll('.main-slider');
  const fHeightHeader = $('.header').outerHeight()
  const $oCatalogHeader = $('.catalog__header')

  if (mainSlider.length === 0) {
    $('.main').css({
      paddingTop: fHeightHeader
    });

    if ($oCatalogHeader) {
      $oCatalogHeader.css({
        top: fHeightHeader
      });
    }
  }
}); 

// loader
document.addEventListener('DOMContentLoaded', () => {
  $('body').removeClass('loading');
})
