export default () => {
  const oCitiesPopup = document.querySelector('.ds-popup-location-cities');
  const oCitiesForm = oCitiesPopup.querySelector('.ds-popup-city-form');
  const oCityInput = oCitiesPopup.querySelector('.ds-popup-city-input');
  const oCitiesFormSubmit = oCitiesForm.querySelector('.ds-popup-city-submit');
  const oLocationTooltipCityNode = document.querySelector('.location__tooltip .location__selected-city');
  const oMobileMenuLocation = document.querySelectorAll('.js-mobile-menu-location');
  const oShopsPopupCityInput = document.querySelector('.ds-popup-cities .ds-popup-city-input');

  oCitiesForm?.addEventListener('click', ({ target: oTarget }) => {
    const oCity = oTarget.closest('.ds-popup-city-select');

    if (oCity)
      oCityInput.value = oCity.textContent;
  });

  oCitiesFormSubmit?.addEventListener('click', () => {
    const sSelectedCity = oCityInput.value;

    if (sSelectedCity.length === 0) {
      oCityInput.style.borderColor = 'red';
      return false;
    }

    window.BX.ajax.runComponentAction(
      'ega:geolocation',
      'setUserLocation',
      {
        mode: 'class',
        data: {
          sCity: sSelectedCity
        }
      }
    ).then((oResponse) => {
      if (oResponse.status == 'success' && oResponse.data.success) {
        let sUserLocation = oResponse.data.userLocation.CITY || oResponse.data.userLocation.SETTLEMENT;

        oLocationTooltipCityNode.innerText = sUserLocation;

        oMobileMenuLocation.forEach((oNode) => {
          const oMobileMenuLocationCity = oNode.querySelector('.js-mobile-menu-location-city');

          if (oMobileMenuLocationCity)
            oMobileMenuLocationCity.innerText = sUserLocation;
        });

        if (oShopsPopupCityInput)
          oShopsPopupCityInput.value = sUserLocation;

        oCitiesPopup.removeAttribute('data-open');
      }
    });
  });
}
