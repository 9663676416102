import noUiSlider from 'nouislider';

window.noUiSlider = noUiSlider;
document.addEventListener('DOMContentLoaded', () => {
  const catalog = document.querySelector('.js-catalog-body');
  if (!catalog) return null;
  const topline    = catalog.querySelector('.catalog__controls');
  const asideWrap  = catalog.querySelector('.aside__wrap');
  const filterWrap = catalog.querySelector('.filter__wrap');

  const setHeight = () => {
    const params          = topline.getBoundingClientRect();
    catalog.style.cssText = `--filter-height: calc(100vh - ${params.bottom + 100}px)`;
  };
  setHeight();
  window.addEventListener('scroll', setHeight);

  topline.addEventListener('mousewheel', e => {
    e.preventDefault();

    asideWrap.scrollTop += e.deltaY;
    filterWrap.scrollTop += e.deltaY;
  });

  const aside  = document.querySelector('.js-catalog-aside');
  const filter = document.querySelector('.js-catalog-filter');
  const $arBtnsFilterOpen = document.querySelectorAll('.js-catalog-filter-open');
  const $arBtnsFilterClose = document.querySelectorAll('.js-filter-close');
  const $arBtnsAsideOpen = document.querySelectorAll('.js-catalog-aside-open');
  const $arBtnsAsideClose = document.querySelectorAll('.js-aside-close');

  $arBtnsAsideOpen.forEach(btn => {
    btn.addEventListener('click', (e) => {
      aside.setAttribute('data-open', '');
    })
  })

  $arBtnsAsideClose.forEach(btn => {
    btn.addEventListener('click', (e) => {
      aside.removeAttribute('data-open');
    })
  });

  $arBtnsFilterOpen.forEach(btn => {
    btn.addEventListener('click', (e) => {
      filter.setAttribute('data-open', '');
    })
  });

  $arBtnsFilterClose.forEach(btn => {
    btn.addEventListener('click', (e) => {
      filter.removeAttribute('data-open');
    })
  });

  document.addEventListener('click', ({target}) => {
    if (!target.closest('.filter__wrap') && !target.closest('.js-catalog-filter-open') && !target.closest('.aside__wrap') && !target.closest('.js-catalog-aside-open') && !target.closest('.filter_preloader')) {
      if (filter.hasAttribute('data-open')) {
        filter.removeAttribute('data-open');
        return false;
      }
      if (aside.hasAttribute('data-open')) {
        aside.removeAttribute('data-open');
        return false;
      }
    }
  });
});

