export default () => {
  const checkout = document.querySelector('.ds-checkout-form');
  const contactInputs = ['ch-first-name', 'ch-last-name', 'ch-phone', 'ch-email'];
  const deliveryInputs = ['ch-city', 'ch-street', 'ch-house', 'ch-flat'];
  const allInputs = [...contactInputs, ...deliveryInputs];
  const cityInputNode = document.getElementById('ch-city');
  const radioDeliveries = checkout.querySelector('.ds-ch-radio-deliveries');
  const radioPayments = checkout.querySelector('[data-content="checkout-payment"] .ds-ch-radio-deliveries');
  const emailInput = document.getElementById('ch-email');
  const selectPickupBtn = radioDeliveries.querySelector('.ds-popup-pickup-open')
  const selectShopBtn = radioDeliveries.querySelector('.ds-popup-shop-open')
  let deliveryError = false;
  let emailError = false;
  let paymentError = false;

  window.checkout.isSuccessValidation = () => checkoutValidation();

  allInputs.forEach(input => {
    const inputNode = document.getElementById(input);

    /**
     * скрываем ошибки при фокусе на поле
     * **/
    inputNode.addEventListener('focus', () => {
      if (inputNode.classList.contains('error')) {
        removeError(inputNode);
      }
    })

    /**
     * показываем ошибку на незаполненном поле при блюре
     * **/
    inputNode.addEventListener('blur', () => {

      if (inputNode.id === 'ch-email') {
        if (isEmailError(inputNode)) {
          setError(inputNode, emailError ? 'Введите корректный email' : 'Обязательное поле');
        }
      } else if (inputNode.id === 'ch-street') {
        setTimeout(function () {
          if (!inputNode.value.length || empty(window.checkout.selectedValues.user.address.street.id)) {
            setError(inputNode, 'Выберите улицу из списка');
          }
        }, 100);
      } else {
        if (isInputError(inputNode)) {
          setError(inputNode);
        }
      }
    })
  })

  /**
   * сброс ошибки при открытии попапов выбора пункта (пвз, магазина)
   * **/
  if (selectPickupBtn) {
    selectPickupBtn.addEventListener('click', () => {
      resetNodeErrors(radioDeliveries);
    })
  }

  if (selectShopBtn) {
    selectShopBtn.addEventListener('click', () => {
      resetNodeErrors(radioDeliveries);
    })
  }

  /**
   * установка ошибки для инпута
   * **/
  function setError(inputNode, text = 'Обязательное поле') {
    const errorMessage = document.createElement('p');
    errorMessage.classList.add('input__error');
    errorMessage.innerHTML = text;
    inputNode.classList.add('error');
    inputNode.after(errorMessage);
  }

  /**
   * удаление ошибки для инпута
   * **/
  function removeError(inputNode) {
    inputNode.classList.remove('error');
    const errorNode = inputNode.nextElementSibling;
    inputNode.parentElement.removeChild(errorNode);
  }

  /**
   * скролл к ошибке
   * **/
  function scrollTo(element) {
    const top = element.getBoundingClientRect().top + window.scrollY - 130;
    window.scrollTo({
      top: top,
      behavior: 'smooth'
    })
  }

  function setDeliveryError(text, sDeliveryType = '') {
    const errorElementBlock = (sDeliveryType.length > 0 ? radioDeliveries.querySelector(`[data-delivery="ch-d-${sDeliveryType}"]`) : radioDeliveries);
    const errorElement = errorElementBlock.querySelector('.ch-order__error');

    if (!errorElement) {
      const errorMessage = document.createElement('p');
      errorMessage.classList.add('ch-order__error');
      errorMessage.innerHTML = text;
      errorElementBlock.append(errorMessage);
    } else {
      errorElement.innerHTML = text;
    }
  }

  function setPaymentError(text) {
    const paymentContent = checkout.querySelector('[data-content="checkout-payment"]');
    const radioPayments = paymentContent.querySelector('.ds-ch-radio-deliveries');
    const errorElement = paymentContent.querySelector('.ch-order__error');
    if (!errorElement) {
      const errorMessage = document.createElement('p');
      errorMessage.classList.add('ch-order__error');
      errorMessage.innerHTML = text;
      radioPayments.append(errorMessage);
    } else {
      errorElement.innerHTML = text;
    }
  }

  function emailValidation(value) {
    const valueFirst = value.split('@');
    const regexp = /^[A-Za-z0-9_.@-]+$/i;
    //проверка на латиницу
    if(!regexp.test(value)) {
      return false;
    }
    //проверка на длину после @
    if (valueFirst.length > 1) {
      const valueSecond = valueFirst[1].split('.');
      return valueSecond.length > 1 && valueSecond[1].length >= 2;
    }
    return false;
  }

  /**
   * валидация инпутов на пустое значение
   * **/
  function isInputError(inputNode) {
    return inputNode.required && !inputNode.value.length
  }

  /**
   * валидация email
   * **/
  function isEmailError(inputNode) {
    //сбрасываем ошибку
    emailError = false;

    //проверяем длину поля
    if (inputNode.value.length) {
      //проверка значения в поле
      emailError = !emailValidation(inputNode.value);
    } else {
      //ошибка пустого поля
      return true;
    }

    return emailError;
  }

  function phoneValidation(inputNode) {
    let phoneNumber = inputNode.value
    let cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');

    return cleanedPhoneNumber.length === 11;
  }

  function getDelivery() {
    const deliveryContent = checkout.querySelector('[data-content="delivery"]');
    const deliveries = deliveryContent.querySelectorAll('input.radio');
    let selectedDelivery = 'empty';

    deliveries.forEach(delivery => {
      selectedDelivery = delivery.checked ? delivery.id.slice(5) : selectedDelivery
    })

    return selectedDelivery
  }

  function getPayment() {
    const paymentContent = checkout.querySelector('[data-content="checkout-payment"]');
    const payments = paymentContent.querySelectorAll('input.radio');
    let selectedPayment = 'empty';

    payments.forEach(payment => {
      selectedPayment = payment.checked ? payment.id.slice(5) : selectedPayment
    })

    return selectedPayment
  }

  function checkoutValidation() {
    let errorInputs = [];
    contactInputs.forEach(input => {
      const inputNode = document.getElementById(input);

      if (input === 'ch-email') {
        if (isEmailError(inputNode)) {
          if (emailError) {
            if (!inputNode.classList.contains('error')) {
              setError(inputNode, 'Введите корректный email');
            }
          } else {
            errorInputs.push(inputNode);
          }
        }
      } else if (input === 'ch-phone') {
        if (!phoneValidation(inputNode)) {
          if (!inputNode.classList.contains('error')) {
            setError(inputNode, 'Введите корректный номер телефона');
          }
          errorInputs.push(inputNode);
        }
      } else {
        if (isInputError(inputNode)) {
          errorInputs.push(inputNode);
        }
      }
    })

    switch (getDelivery()) {
      case 'pickup':
        if (!cityInputNode.value.length) {
          errorInputs.push(cityInputNode);
        }
        if (!window.checkout.selectedPickup) {
          deliveryError = true;
          setDeliveryError('Необходимо выбрать пункт выдачи', 'pickup')
        } else {
          deliveryError = false;
        }
        break;
      case 'shop':
        if (!cityInputNode.value.length) {
          errorInputs.push(cityInputNode);
        }
        if (!window.checkout.selectedShop) {
          deliveryError = true;
          setDeliveryError('Необходимо выбрать магазин', 'shop')
        } else {
          deliveryError = false;
        }
        break;
      case 'empty':
        deliveryError = true;
        setDeliveryError('Необходимо выбрать способ доставки');
        deliveryInputs.forEach(input => {
          const inputNode = document.getElementById(input);
          if (inputNode.required && !inputNode.value.length) {
            errorInputs.push(inputNode);
          }
        })
        break;
      default:
        deliveryError = false;
        deliveryInputs.forEach(input => {
          const inputNode = document.getElementById(input);
          if (input === 'ch-street') {
            if (!inputNode.value.length && !inputNode.classList.contains('error')) {
              setError(inputNode, 'Выберите улицу из списка');
            }
          } else {
            if (inputNode.required && !inputNode.value.length) {
              errorInputs.push(inputNode);
            }
          }
        })
        break;
    }

    if (getPayment() === 'empty') {
      paymentError = true;
      setPaymentError('Необходимо выбрать способ оплаты');
    } else {
      paymentError = false;
    }

    if (errorInputs.length) {
      errorInputs.forEach(inputNode => {
        if (!inputNode.classList.contains('error')) {
          setError(inputNode)
        }
      })
      //скролл к первой ошибке
      scrollTo(errorInputs[0]);
    } else if (emailError) {
      //скролл к ошибке email
      scrollTo(emailInput)
    } else if (deliveryError) {
      //скролл к ошибке невыбранного пункта
      scrollTo(radioDeliveries);
    } else if (paymentError) {
      scrollTo(radioPayments);
    } else {
      //успешная валидация
      return true;
    }
    return false;
  }

  window.checkout.deleteDeliveryError = () => {
    resetNodeErrors(radioDeliveries);
  }

  window.checkout.deletePaymentError = () => {
    const paymentContent = checkout.querySelector('[data-content="checkout-payment"]');
    const radioPayments = paymentContent.querySelector('.ds-ch-radio-deliveries');
    const errorElement = paymentContent.querySelector('.ch-order__error');
    if (errorElement) {
      radioPayments.removeChild(errorElement)
    }
  }

  function resetNodeErrors(oNode) {
    const errorElements = oNode.querySelectorAll('.ch-order__error');

    errorElements.forEach(oError => {
      oError.remove();
    });
  }
}