import Swiper from 'swiper';

document.addEventListener('DOMContentLoaded', () => {

  const sliders = document.querySelectorAll('[id^="js-lookbook-slider-"]');

  if (sliders.length > 0) {
    sliders.forEach((slider, i) => {
      new Swiper(slider, {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 20,
        allowTouchMove: true,
        breakpoints: {
          768: {
            slidesPerView: 3,
            allowTouchMove: false
          }
        },
        pagination:{
          el: `#js-lookbok-pagination-${i}`,
          type: 'bullets',
          clickable: true,
          bulletActiveClass: 'm-active'
        }
      });
    });
  }

});