export default () => {
  window.citiesPopup = {};
  /**
   * выбор города
   * **/
  const citiesPopup = document.querySelector('.ds-popup-cities');
  const citiesPopupOpen = document.querySelectorAll('.ds-popup-cities-open');
  const citiesForm = citiesPopup.querySelector('.ds-popup-cities-form');
  const cityInput = citiesPopup.querySelector('.ds-popup-cities-input');
  const cityList = citiesPopup.querySelector('.ds-popup-cities-list');
  const citiesFormSubmit = citiesForm.querySelector('.ds-popup-cities-submit');
  let citiesData = null;
  window.citiesPopup.selectedCity = null;
  window.citiesPopup.cities = [];
  window.citiesPopup.countryId = "";

  if (citiesForm) {
    citiesForm.addEventListener('click', ({target}) => {
      const city = target.closest('.ds-popup-cities-select');

      if (city) {
        setCityInInput(city);
      }

      //мисклик для закрытия выпадающего списка подсказок
      const curClickInput = target.closest('.ds-popup-cities-input');
      const curClickList = target.closest('.ds-popup-cities-list');

      if (cityList && !(curClickInput || curClickList)) {
        cityList.removeAttribute('data-open')
      }
    })

    /**
     * отправка формы выбора города
     * **/
    citiesForm.addEventListener('submit', (e) => {
      if (window.checkout) {
        e.preventDefault();

        if (!window.citiesPopup.selectedCity) {
          //вывод ошибки
          cityInput.classList.add('error');
          if (!cityInput.parentElement.querySelector('.input__error')) {
            const errorNode = document.createElement('p');
            errorNode.classList.add('input__error')
            errorNode.innerHTML = 'Выберите город'
            cityInput.after(errorNode)
          }
        } else {
          showPreloader();
          //обновление дефолтной страны
          const countryNode = document.getElementById('js-select-open-country');
          if (countryNode) {
            const currentCountry = Object.values(window.checkout.countries).find(({id}) => id === countryNode.getAttribute('data-selected-id'));
            if (currentCountry && currentCountry.name !== window.checkout.address.default.country) {
              window.checkout.address.default.country = currentCountry.name;
              //обновляем запись страны в дадате
              window.dadata.country = currentCountry.name
              //обновляем страну, город и регион по-умолчанию
              window.checkout.address.default.region = window.citiesPopup.selectedCity.region;
              window.checkout.address.default.city = window.citiesPopup.selectedCity.name;
            }
          }
          //обновляем доставки, точки пвз и магазинов
          window.checkout.updateCheckoutWindowData(window.checkout.address.default.country, window.citiesPopup.selectedCity.region, {id: window.citiesPopup.selectedCity.id, name: window.citiesPopup.selectedCity.name});

          //обновляем название города в кнопках для открытия попапа выбора города
          citiesPopupOpen.forEach(openButton => {
            openButton.innerHTML = 'г. ' + window.citiesPopup.selectedCity.name;
          })
          //записываем город в поле города чекаута
          const checkoutCityInput = document.getElementById('ch-city');
          checkoutCityInput.value = window.citiesPopup.selectedCity.name;
          //записываем данные для дадаты
          const newCityObject = {
            id: null,
            itemId: window.citiesPopup.selectedCity.id,
            name: window.citiesPopup.selectedCity.name,
            text: window.citiesPopup.selectedCity.name,
            data: {
              region: window.citiesPopup.selectedCity.region
            }
          }
          window.dadataAddress.city = newCityObject;
          window.dadata.setItem('city', newCityObject);
          //записываем выбранный город в selectedValues
          window.checkout.selectedValues.user.address.city = newCityObject;
          window.checkout.selectedValues.user.address.fias = "";
          window.checkout.selectedValues.user.address.zip = "";
          window.checkout.city.checked = window.citiesPopup.selectedCity.name;

          //очищаем поле с выбором города
          cityInput.value = '';
          window.citiesPopup.selectedCity = null;

          //сбрасываем выбранный адрес на "новый адрес"
          window.checkout.setNewAddress();
        }
      }
    })

    //устанавливаем список популярных городов
    if (window.checkout && window.checkout.popularCities) {
      updatePopularCities(window.checkout.popularCities)
    }
  }

  /**
   * кастомный select
   * **/
  const container = document.querySelector('#js-select-country')
  const selectText = document.querySelector('#js-select-open-country');
  
  if (selectText){
    //устанавливаем текущий id
    const curCountryId = selectText.getAttribute('data-selected-id');
    window.citiesPopup.countryId = curCountryId ? curCountryId : "";
  }

  document.addEventListener('click', ({target}) => {
    const selectOpen = target.closest('#js-select-open-country');
    const selectContainer = target.closest('#js-select-country');

    if (selectOpen) {
      if (container.hasAttribute('data-open')) {
        container.removeAttribute('data-open');
      } else {
        container.setAttribute('data-open', '');
      }
    }

    if (selectContainer) {
      const button = target.closest('[data-select-id]');

      if (button) {
        selectContainer.removeAttribute('data-open');

        const allButtons = selectContainer.querySelectorAll('[data-select-id]');
        allButtons.forEach(btn => btn.classList.remove('m-selected'));
        button.classList.add('m-selected');

        selectText.innerHTML = button.innerHTML;
        const selectedId = button.getAttribute('data-select-id');
        selectText.setAttribute('data-selected-id', selectedId)

        updateCountry(selectedId);
      }
    } else {
      if (container) {
        container.removeAttribute('data-open');
      }
    }
  });

  /**
   * вывод подсказок при вводе названия города
   * **/
  if (window.checkout) {
    let debounceInstance = null;

    if (cityInput) {
      cityInput.addEventListener('input', () => {
        //сбрасываем выбранный город при вводе в поле
        window.citiesPopup.selectedCity = null;
        if (cityInput.value.length > 2) {
          //отправка запроса
          clearTimeout(debounceInstance);
          debounceInstance = setTimeout(
             () => {
               getCitiesList(cityInput.value)
                  .then(result => {
                    if (result.status === 'success' && result.data.success) {
                      window.checkout.city.checked = result.data.checked;
                      window.checkout.city.items = result.data.cities;

                      if (result.data.cities && result.data.cities.length) {
                        citiesData = result.data.cities;
                        setCitiesList(citiesData)
                      } else {
                        citiesData = null;
                        clearCitiesList('Не удалось ничего найти')
                      }
                    }
                  })
                  .catch(error => {
                    console.group('getCitiesList');
                    console.error(error);
                    console.groupEnd();
                    clearCitiesList('Не удалось ничего найти');
                  })
             },
             300
          )
        } else {
          clearCitiesList('Введите значение, чтобы увидеть подсказки')
        }
      })

      /**
       * открытие выпадающего списка на фокус
       * **/
      cityInput.addEventListener('focus', () => {
        //сброс ошибки
        cityInput.classList.remove('error');

        if (!citiesData) {
          clearCitiesList('Введите значение, чтобы увидеть подсказки')
        }

        cityList.setAttribute('data-open', '');
      })
    }
    if (cityList) {
      /**
       * выбор города из выпадающего списка
       * **/
      cityList.addEventListener('click', ({target}) => {
        const listItem = target.closest("[data-city-id]");
        if (listItem) {
          setCityInInput(listItem);
          cityList.removeAttribute('data-open');
        }
      })
    }
  }

  /**
   * запрос на получение списка городов
   * **/
  function getCitiesList(city) {
    if (!window.citiesPopup.countryId && window.checkout){
      window.citiesPopup.countryId = window.checkout.countries['Россия']
         ?
          window.checkout.countries['Россия'].id ? window.checkout.countries['Россия'].id : window.checkout.countries['Россия'].ID
         : '';
    }

    if (window.BX) {
      return window.BX.ajax.runComponentAction('ega:order', 'getCityList',
         {
           mode: 'class',
           data: {
             countryId: window.citiesPopup.countryId,
             cityName: city
           }
         })

    } else {
      return new Promise((resolve) => {
        resolve({
          status: 'success',
          data: {
            success: true,
            cities: [
              {
                id: 'city1',
                name: 'Москва',
                region: 'московская область'
              },
              {
                id: 'city2',
                name: 'Мурманск',
                region: 'московская область'
              },
              {
                id: 'city3',
                name: 'Махачкала',
                region: 'московская область'
              },
              {
                id: 'city4',
                name: 'Магадан',
                region: 'московская область'
              },
              {
                id: 'city5',
                name: 'Магнитогорск',
                region: 'московская область'
              }
            ]
          }
        });
      });
    }
  }

  /**
   * записывает список городов
   * **/
  function setCitiesList(list) {
    cityList.innerHTML = '';
    list.forEach(city => {
      setCityItem('button', {id: city.id, text: city.name, region: city.region})
    })
  }

  /**
   * очищает список городов и вставляет текст
   * @param text - текст для подсказки
   * **/
  function clearCitiesList(text) {
    cityList.innerHTML = '';
    setCityItem('text', {text})
  }

  /**
   * вставляет элемент списка в выпадашку
   * @param itemData - данные для вывода {id: '', text: '', region: ''}
   * @param type - 'button'/'text'
   * **/
  function setCityItem(type, itemData) {
    const item = document.createElement('li');
    if (type === 'button') {
      item.innerHTML = `<button class="select__item" type="button" data-city-id="${itemData.id}" data-region="${itemData.region}">${itemData.text}</button>`
    } else {
      item.innerHTML = `<p class="select__item"><span>${itemData.text}</span></p>`
    }
    cityList.appendChild(item);
  }

  /**
   * записывает город в поле и window.citiesPopup.selectedCity
   * **/
  function setCityInInput(nodeItem) {
    //сброс ошибки
    const errorNode = cityInput.parentElement.querySelector('.input__error');
    if (errorNode) {
      cityInput.classList.remove('error');
      cityInput.parentElement.removeChild(errorNode);
    }
    //запись данных
    cityInput.value = nodeItem.textContent;
    window.citiesPopup.selectedCity = {
      id: nodeItem.getAttribute('data-city-id'),
      region: nodeItem.getAttribute('data-region'),
      name: nodeItem.textContent
    }
  }

  /**
   * смена страны
   * **/
  function updateCountry(countryId) {
    window.citiesPopup.countryId = countryId;
    if (window.checkout) {
      getPopularCitiesList(countryId)
         .then(result => {
           if (result.status === 'success' && result.data.success) {
             //обновление популярных городов
             window.checkout.popularCities = result.data.cities;
             updatePopularCities(result.data.cities)
             //очищаем поле с выбором города
             cityInput.value = '';
             window.citiesPopup.selectedCity = null;
           }
         })
         .catch(error => {
           console.groupCollapsed('getPopularCitiesList');
           console.error(error);
           console.groupEnd();
         })
    }

    /**
     * запрос на получение списка популярных городов городов
     * **/
    function getPopularCitiesList(countryId) {

      if (window.BX) {
        return window.BX.ajax.runComponentAction('ega:order', 'getPopularCity',
           {
             mode: 'class',
             data: {countryId}
           })

      } else {
        return new Promise((resolve) => {
          resolve({
            status: 'success',
            data: {
              success: true,
              cities: [
                {
                  id: 'city1',
                  name: 'Москва',
                  region: 'московская область'
                },
                {
                  id: 'city2',
                  name: 'Мурманск',
                  region: 'московская область'
                },
                {
                  id: 'city3',
                  name: 'Махачкала',
                  region: 'московская область'
                },
                {
                  id: 'city4',
                  name: 'Магадан',
                  region: 'московская область'
                },
                {
                  id: 'city5',
                  name: 'Магнитогорск',
                  region: 'московская область'
                }
              ]
            }
          });
        });
      }
    }
  }

  function updatePopularCities(cities){
    const popularList = citiesPopup.querySelector('.ds-popup-cities-popular');
    //очищаем список
    popularList.innerHTML = '';
    if (cities.length) {
      popularList.parentElement.classList.remove('m-hidden')
      cities.forEach(city => {
        const cityNode = document.createElement('li');
        cityNode.innerHTML = `<button class="popup-cities__city ds-popup-cities-select" type="button" data-city-id="${city.id}" data-region="${city.region}">${city.name}</button>`
        popularList.appendChild(cityNode);
      })
    }else{
      popularList.parentElement.classList.add('m-hidden')
    }
  }

  function showPreloader() {
    if (cityInput) {
      cityInput.setAttribute('disabled', '');
    }
    if (selectText) {
      selectText.setAttribute('disabled', '');
    }
    if (citiesFormSubmit) {
      citiesFormSubmit.classList.add('preloader');
    }
  }

  function hidePreloader() {
    if (cityInput) {
      cityInput.removeAttribute('disabled');
    }
    if (selectText) {
      selectText.removeAttribute('disabled');
    }
    if (citiesFormSubmit) {
      citiesFormSubmit.classList.remove('preloader');
    }
  }

  window.citiesPopup.showPreloader = () => showPreloader();
  window.citiesPopup.hidePreloader = () => hidePreloader();
  window.citiesPopup.endLoadingData = () => {
    hidePreloader();
    window.popups.close('cities');
  };
  window.citiesPopup.updateTitles = (title) => {
    //обновляем название города в кнопках для открытия попапа выбора города
    citiesPopupOpen.forEach(openButton => {
      openButton.innerHTML = title;
    })
  };
  if (window.checkout) {
    window.checkout.updateCountry = (countryObject, region, city) => {
      //запрос при смене страны на обновление популярных городов
      updateCountry(countryObject.id);
      //обновляем записи в выборе страны
      const selectContainer = document.querySelector('#js-select-country');
      if (selectContainer) {
        const allButtons = selectContainer.querySelectorAll('[data-select-id]');
        allButtons.forEach(btn => {
          if (btn.getAttribute('data-select-id') === countryObject.id) {
            btn.classList.add('m-selected');
          } else {
            btn.classList.remove('m-selected')
          }
        });
        selectText.innerHTML = countryObject.name;
        selectText.setAttribute('data-selected-id', countryObject.id)
      }
      //обновляем запись в window.checkout.default
      window.checkout.address.default.region = region;
      window.checkout.address.default.city = city;
      window.checkout.address.default.country = countryObject.name;

      //обновляем запись в дадате
      window.dadata.country = countryObject.name
    }
  }
}
