import { disablePageScroll, enablePageScroll } from 'scroll-lock';

export default function(params){
    //Get the element that should scroll when page scrolling is disabled
    const $scrollableElement = document.querySelector('body');
    if (params) {
        // Also, pass the element to the argument and enable scrolling on the page
        enablePageScroll($scrollableElement);

    } else {
        //Pass the element to the argument and disable scrolling on the page
        disablePageScroll($scrollableElement);
    }
}

