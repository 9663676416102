import {dadataCheckout} from "%modules%/checkout/checkout-dadata";

/**
 * дадата
 * **/
document.addEventListener('DOMContentLoaded', () => {
  const dadataPage = document.querySelector('.ds-dadata-page');
  if (dadataPage) {
    new dadataCheckout();
  }
});