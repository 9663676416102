const fav = document.querySelectorAll('.fav');

fav.forEach(function (el) {

    el.addEventListener('click', (e) => {

        const f = e.target.closest('button');

        if (f.classList.contains('active')) {
            f.classList.remove('active')
        } else {
            f.classList.add('active');
        }
    })

});
