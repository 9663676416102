import maskInput from "vanilla-text-mask/dist/vanillaTextMask";

document.addEventListener('DOMContentLoaded', () => {
  const phoneMask = ['+', '7', ' ', '(', /[9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
  const authPhoneInput = document.querySelector('#auth #PHONE_NUMBER');
  const regPhoneInput = document.querySelector('.reg__container #USER_PHONE_NUMBER');
  const phoneInputs = [authPhoneInput, regPhoneInput]

  if (authPhoneInput || regPhoneInput) {
    phoneInputs.forEach(phoneInput => {
      if (phoneInput !== null) {
        const maskedInputController = maskInput({
          inputElement: phoneInput,
          mask: phoneMask
        });
      }
    });
  }
})