import Swiper from 'swiper';
document.addEventListener('DOMContentLoaded', ()=>{



    let itemsSliders = document.getElementsByClassName('js-main-slider');

    Array.from(itemsSliders).forEach(function (slider) {
        const allSlides = slider.querySelectorAll('.swiper-slide');

        //  console.log(slider.parentElement.getElementsByClassName('custom-slider__arrow--next')[0])
        if (allSlides.length > 1) {
            let mainSlider = new Swiper(slider, {
                loop: true,
                //effect: 'fade',
                speed: 800,
                //  fadeEffect: {crossFade: true},
                spaceBetween: 0,
                slidesPerView: 1,
                autoplay: {
                    delay: 7000,
                    disableOnInteraction: false
                },
                navigation: {
                    nextEl: slider.parentElement.getElementsByClassName('custom-slider__arrow--next')[0],
                    prevEl: slider.parentElement.getElementsByClassName('custom-slider__arrow--prev')[0]
                },
                pagination: false,
                breakpoints: {}
            });
        }
    })
});
