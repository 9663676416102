import maskInput from "vanilla-text-mask/dist/vanillaTextMask";

document.addEventListener('DOMContentLoaded', () => {
  const phoneMask = ['+', '7', ' ', '(', /[9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
  const phoneInput1 = document.getElementById('address-phone');
  const phoneInput2 = document.getElementById('pickup-phone');
  const phoneInput3 = document.getElementById('shop-phone');
  const phoneInputs = [phoneInput1, phoneInput2, phoneInput3]

  if (phoneInput1 || phoneInput2 || phoneInput3) {
    phoneInputs.forEach(phoneInput => {
      const maskedInputController = maskInput({
        inputElement: phoneInput,
        mask: phoneMask
      })
    })
  }

  const addressEditPage = document.querySelector('.addresses__edit');
  if (addressEditPage) {
    window.checkout.city = { checked: '' };
    window.checkout.setNewAddress = () => { };
    window.checkout.updateDeliveryPrice = (type, price) => { };
    window.checkout.resetPoint = (pointType) => resetPoint(pointType);
    window.checkout.updateCheckoutWindowData = (country, region, cityObject) => updateCheckoutWindowData(country, region, cityObject);
    if (!window.checkout.selectedValues) {
      window.checkout.selectedValues = {
        user: {
          profileId: "",
          name: "",
          lastName: "",
          phone: "",
          email: "",
          address: {
            city: { id: null, itemId: null, text: '' },
            street: { id: null, itemId: null, text: '' },
            house: { id: null, itemId: null, text: '' },
            flat: ""
          }
        },
        delivery: {
          deliveryId: "",
          pickupId: "",
          shopId: "",
          price: ""
        },
        paymentSystem: {
          id: "",
          sum: "",
          url: ""
        },
        comment: ''
      }
    }
    //заглушка для paySystem
    if (!window.checkout.paySystem) {
      window.checkout.paySystem = {
        "6": {
          id: "6",
          name: "Онлайн оплата",
          url: "url"
        },
        "14": {
          id: "14",
          name: "Оплата при получении",
          url: "url"
        }
      }
    }

    function updateCheckoutWindowData(country, region, cityObject, resetPoints = true) {
      // addressEditPreloader();
      //обновление страны, если другая
      if (country && country !== window.checkout.address.default.country) {
        const newCountry = window.checkout.countries[country];
        if (newCountry) {
          window.checkout.updateCountry(newCountry, region, cityObject.name)
        }
      }

      //обновление точек пвз
      updatePickupPopup(cityObject.name, region, resetPoints, cityObject.fias, cityObject.zip)
      //обновление точек магазинов
      updateShopsPopup(cityObject, resetPoints)

      //сброс полей улица, дом, квартира
      if (window.dadata.resetAddressInputs) {
        window.dadata.resetAddressInputs();
      }
    }

  }

  /**
  * сброс выбранного пункта (пвз, магазин)
  * @param pointType - pickup/shop
  * **/
  function resetPoint(pointType) {
    const pointData = document.querySelector(`[data-content='delivery-${pointType}']`);
    const pointTitle = pointData.querySelector(`[data-content='${pointType}-title']`);
    const pointAddress = pointData.querySelector(`[data-content='${pointType}-address']`);
    pointTitle.innerHTML = '';
    pointAddress.innerHTML = '';
    pointData.classList.add('m-hidden');

    if (pointType === 'pickup') {
      window.checkout.selectedPickup = null;
    } else {
      window.checkout.selectedShop = null;
    }
    //показываем кнопку выбора пункта
    const deliveryRadioSelect = document.querySelector(`.ds-popup-${pointType}-open`)
    deliveryRadioSelect.classList.remove('m-hidden');

    //убираем подсветку пункта в списке
    const popup = document.querySelector(`.ds-popup-${pointType}`);
    popup.querySelectorAll(`.ds-popup-${pointType}-point`).forEach(pointItem => {
      pointItem.removeAttribute('data-active');
    });
  }

  /**
   * Обновление точек попапа пвз
   * @param city - город для отправки в запросе
   * @param region - регион для отправки в запросе
   * @param resetPoints - сбрасывать ли выбранный пункт (по умолчанию true)
   * **/
  function updatePickupPopup(city, region, resetPoints, fias = '', zip = '') {
    if (window.popupPickup && window.popupPickup.showPreloader) {
      window.popupPickup.showPreloader();
    }
    getAvailablePickupPoints(city, region, fias, zip)
      .then(result => {
        if (result.status === 'success' && result.data.success) {
          window.checkout.pickupPoints = result.data.pickupPoints;
          //обновляем точки
          window.checkout.updatePickupPopup(result.data.pickupPoints);
          //сброс выбранного пункта пвз
          if (window.popupPickup && window.popupPickup.resetSearch) {
            //сбрасываем поиск
            window.popupPickup.resetSearch();
          }
          if (resetPoints) {
            window.checkout.resetPoint('pickup')
          }
        }
        if (window.popupPickup && window.popupPickup.hidePreloader) {
          window.popupPickup.hidePreloader();
        }
      })
      .catch(error => {
        console.group('getAvailablePickupPoints');
        console.error(error);
        console.groupEnd();
        if (window.popupPickup && window.popupPickup.hidePreloader) {
          window.popupPickup.hidePreloader()
        }
      })

    /**
     * запрос на получение точек пвз
     * **/
    function getAvailablePickupPoints(city, region, fias, zip) {
      if (window.BX) {
        return window.BX.ajax.runComponentAction('ega:order', 'getPickupPoints',
          {
            mode: 'class',
            data: {
              region,
              city,
              fias,
              zip
            },
          })
      } else {
        return new Promise((resolve) => {
          //setTimeout для эмуляции ожидания ответа сервера
          setTimeout(() => {
            resolve({
              status: 'success',
              data: {
                success: true,
                pickupPoints: [
                  {
                    id: '1003062711',
                    name: 'На Хорошёвском шоссе 27',
                    address: 'Хорошёвское ш., 27',
                    workTime: 'Пн-Пт 10:00-21:00',
                    coords: [55.77923865906552, 37.52356959153274],
                    priceFormatted: '300 ₽',
                    price: '300',
                    typeText: 'Пункт выдачи заказа',
                    providerKeyText: "СДЭК"
                  },
                  {
                    id: '1003062714',
                    name: 'ТРЦ "Мозаика"',
                    address: 'ул, 7-я Кожуховская ул., 9 ТРЦ "Мозаика"',
                    workTime: 'Пн-Пт 10:00-20:00, Сб-Вс 10:00-18:00',
                    coords: [55.716468154586934, 37.6731217461949],
                    priceFormatted: '300 ₽',
                    price: '300',
                    typeText: 'Пункт выдачи заказа',
                    providerKeyText: "СДЭК"
                  },
                  {
                    id: '1003062715',
                    name: 'ТРЦ Columbus',
                    address: 'Кировоградская ул., 13А ТРЦ Columbus',
                    workTime: 'Пн-Пт 10:00-20:00, Сб-Вс 10:00-18:00',
                    coords: [55.6116990331495, 37.60723530006875],
                    priceFormatted: '300 ₽',
                    price: '300',
                    typeText: 'Пункт выдачи заказа',
                    providerKeyText: "СДЭК"
                  }
                ]
              }
            });
          }, 2000)

        });
      }
    }
  }

  /**
   * Обновление точек попапа магазинов
   * @param cityObject - город для отправки в запросе {name, id}
   * @param resetPoints - сбрасывать ли выбранный пункт (по умолчанию true)
   * **/
  function updateShopsPopup(cityObject, resetPoints) {
    if (window.popupShop && window.popupShop.showPreloader) {
      window.popupShop.showPreloader();
    }
    getAvailableShopsPoints(cityObject)
      .then(result => {
        if (result.status === 'success' && result.data.success) {
          window.checkout.shopsPoints = result.data.shopsPoints;
          //обновляем точки на карте
          window.checkout.updateShopsPopup(result.data.shopsPoints);
          //сбрасываем поиск
          if (window.popupShop && window.popupShop.resetSearch) {
            window.popupShop.resetSearch();
          }
          //сброс выбранного пункта пвз
          if (resetPoints) {
            window.checkout.resetPoint('shop')
          }
        }
        if (window.popupShop && window.popupShop.hidePreloader) {
          window.popupShop.hidePreloader();
        }
      })
      .catch(error => {
        console.group('getAvailableShopsPoints');
        console.error(error);
        console.groupEnd();
        if (window.popupShop && window.popupShop.hidePreloader) {
          window.popupShop.hidePreloader();
        }
      })

    /**
     * запрос на получение точек магазинов
     * **/
    function getAvailableShopsPoints(cityObject) {
      let data = {};
      if (cityObject.id) {
        data = {
          id: cityObject.id
        }
      } else {
        data = {
          name: cityObject.name
        }
      }

      if (window.BX) {
        return window.BX.ajax.runComponentAction('ega:order', 'getShopPoints',
          {
            mode: 'class',
            data
          })
      } else {
        return new Promise((resolve) => {
          //setTimeout для эмуляции ожидания ответа сервера
          setTimeout(() => {
            resolve({
              status: 'success',
              data: {
                success: true,
                shopsPoints: [
                  {
                    id: '2003062711',
                    name: 'ТРЦ "Гранд Юг"',
                    address: 'ул. Кировоградская, д. 15, ТРЦ «Гранд Юг»',
                    workTime: 'Пн-Вс 10:00-21:00',
                    coords: [56.010233, 37.859322]
                  },
                  {
                    id: '2003062714',
                    name: 'ТРЦ "Мозаика"',
                    address: 'ул, 7-я Кожуховская ул., 9 ТРЦ "Мозаика"',
                    workTime: 'Пн-Пт 10:00-20:00, Сб-Вс 10:00-18:00',
                    coords: [55.716468154586934, 37.6731217461949]
                  },
                  {
                    id: '2003062715',
                    name: 'ТРЦ Columbus',
                    address: 'Кировоградская ул., 13А ТРЦ Columbus',
                    workTime: 'Пн-Пт 10:00-20:00, Сб-Вс 10:00-18:00',
                    coords: [55.6116990331495, 37.60723530006875]
                  }
                ]
              }
            });
          }, 2000)

        });
      }
    }
  }

  function addressEditPreloader(isShow = true) {
    const checkoutForm = document.querySelector('.ds-checkout-form');
    //поле с выбором адреса
    const checkoutAddressSelect = checkoutForm.querySelector('#js-select-open');
    //блок с доставкой
    const checkoutDeliveries = document.querySelector('.ds-ch-radio-deliveries');
    //блок с оплатами
    const checkoutPayment = document.querySelector("[data-content='checkout-payment']");
    //кнопка оформления заказа
    const checkoutSubmit = document.querySelector(".ds-checkout-submit");
    //все поля для ввода в чекауте
    const allInputs = checkoutForm.querySelectorAll('.input');

    if (isShow) {
      //показываем прелоадер
      allInputs.forEach(input => {
        if (input) {
          input.setAttribute('disabled', '');
        }
      })

      if (checkoutDeliveries) {
        checkoutDeliveries.classList.add('skeleton');
      }
      if (checkoutPayment) {
        checkoutPayment.classList.add('skeleton');
      }
      if (checkoutSubmit) {
        checkoutSubmit.setAttribute('disabled', '');
      }
      if (checkoutAddressSelect) {
        checkoutAddressSelect.setAttribute('disabled', '');
      }
    } else {
      //скрываем прелоадер
      allInputs.forEach(input => {
        if (input) {
          input.removeAttribute('disabled');
        }
      })

      if (checkoutDeliveries) {
        checkoutDeliveries.classList.remove('skeleton');
      }
      if (checkoutPayment) {
        checkoutPayment.classList.remove('skeleton');
      }
      if (checkoutSubmit) {
        checkoutSubmit.removeAttribute('disabled');
      }
      if (checkoutAddressSelect) {
        checkoutAddressSelect.removeAttribute('disabled');
      }
    }
  }
})