export default () => {
  const linkButton = document.querySelector(".js-link-button");
  const popupStore = document.querySelector(".js-popup-store");
  const popupStoreClose = popupStore.querySelector(".js-store-close");
  const btnMap = document.querySelector(".js-store-btn-map");
  const btnList = document.querySelector(".js-store-btn-list");
  const storeList = document.querySelector(".js-store-list");
  const mapEl = document.querySelector(".js-map-store");
  const storeDescription = document.querySelector(".js-store-description");

  linkButton.addEventListener("click", () => {
    popupStore.style.display = "block";
  });

  // Открываем карту при клике на кнопку.
  btnMap.addEventListener("click", () => {
    btnList.classList.remove("active");
    btnMap.classList.add("active");

    storeList.style.display = "none";
    mapEl.style.display = "block";
    storeDescription.style.display = "none";
  });

  // Показываем карту и список магазинов при ширине больше чем 767
  window.addEventListener("resize", () => {
    if (window.innerWidth > 767) {
      storeList.style.display = "block";
      mapEl.style.display = "block";
      storeDescription.style.display = "block";
    }
  });

  //закрытие попапа
  popupStoreClose.addEventListener('click', () => {
    popupStore.style.display = "none";
  });

  //мисклик для закрытия попапа
  popupStore.addEventListener('click', ({ target }) => {
    if (!(target.closest(`.js-popup-store-body`) || target.nodeName.toLowerCase() === 'ymaps')) {
      popupStore.style.display = "none";
    }
  });
};
