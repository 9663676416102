export default () => {
    const arPopupFilters = document.querySelectorAll('.popup-filter');

    if (arPopupFilters.length === 0)
        return;

    arPopupFilters.forEach(oFilter => {
        initPopupFilter(oFilter);
    });

    function initPopupFilter(oFilter) {
        const sPopupType = oFilter.closest('.popup-pickup, .popup-store')?.dataset.popupType;
        const arPopupFilterBtns = oFilter.querySelectorAll('.popup-filter__variant');
        const oPopupFilterBottom = oFilter.querySelector('.popup-filter__bottom');
        const oPopupFilterClearBtn = oPopupFilterBottom.querySelector('.popup-filter__clear');

        if (arPopupFilterBtns.length > 0)
            arPopupFilterBtns.forEach(oFilterBtn => {
                oFilterBtn.addEventListener('click', setFilter)
            });

        oPopupFilterClearBtn?.addEventListener('click', clearFilter);

        function setFilter({ target: oTarget }) {
            const { dataset: { value: sFilterValue } } = oTarget;

            let sBottomBlockToggleType = 'hide',
                iFilterCount = null,
                arFilteredItems = [],
                arFilterValues = window.checkout[sPopupType + 'PopupFilter'] || [];

            if (oTarget.classList.contains('selected')) {
                let iValuePos = arFilterValues.findIndex(sValue => sValue === sFilterValue);

                arFilterValues.splice(iValuePos, 1);
                oTarget.classList.remove('selected');
            } else {
                arFilterValues.push(sFilterValue);
                oTarget.classList.add('selected');
            }

            if (arFilterValues.length > 0) {
                sBottomBlockToggleType = 'show';

                arFilteredItems = window.checkout[sPopupType + 'Points'].filter((oItem) => {
                    for (let i = 0; i < arFilterValues.length; i++) {
                        if (oItem[arFilterValues[i]])
                            return true;
                    }

                    return false;
                });

                iFilterCount = arFilteredItems.length;

                if (sPopupType === 'pickup')
                    window.checkout.updatePickupPopup(arFilteredItems);
                else if (sPopupType === 'shops')
                    window.checkout.updateShopsPopup(arFilteredItems);
            } else {
                clearFilter();
            }

            toggleBottomBlock(sBottomBlockToggleType, iFilterCount);
            window.checkout[sPopupType + 'PopupFilter'] = arFilterValues;
        }

        function clearFilter() {
            arPopupFilterBtns.forEach(oFilterBtn => {
                oFilterBtn.classList.remove('selected');
            });

            toggleBottomBlock('hide');
            window.checkout[sPopupType + 'PopupFilter'] = [];
            if (sPopupType === 'pickup')
                window.checkout.updatePickupPopup(window.checkout.pickupPoints);
            else if (sPopupType === 'shops')
                window.checkout.updateShopsPopup(window.checkout.shopsPoints);
        }

        function toggleBottomBlock(sType, iFilterCount = 0) {
            const oFilterCountSpan = oPopupFilterBottom.querySelector('.popup-filter__count--filter');
            const oTotalCountSpan = oPopupFilterBottom.querySelector('.popup-filter__count--total');
            const iTotalCount = window.checkout[sPopupType + 'Points'].length;

            switch (sType) {
                case 'show':
                    oFilterCountSpan.innerText = iFilterCount;
                    oTotalCountSpan.innerText = iTotalCount;

                    oPopupFilterBottom.classList.add('show');
                    break;

                case 'hide':
                    oPopupFilterBottom.classList.remove('show');
                    break;

                default:
                    break;
            }
        }
    }
};